@import "src/themes/mojito/styles/index.scss";
.accordion {
  @extend .u-box--small;
  @extend .u-spacing-4;
  @extend .u-shadow-small;
  padding: 0;
  text-align: left;

  &__header {
    color: getvar($colors, "neutral", "900");
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: space(1.25) space(1.25);
    cursor: pointer;

    @include md {
      padding: space(1.5) space(1.75);
    }

    svg {
      height: space(1.25);
      width: space(1.25);
    }
  }

  &__icon {
    svg {
      margin-right: 0.75rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &__title {
    width: 90%;
  }

  &__subtitle {
    font-size: space(0.875) !important;
    font-weight: 400;
    color: getvar($colors, "neutral", "700");
  }

  &__button {
    display: inline-flex;
    transition: transform 0.2s ease-in-out;
    align-self: baseline;
    margin-top: space(0.2);
  }

  &__body {
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    color: getvar($colors, "neutral", "900");

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--isOpen {
    .accordion__button {
      transform: rotate(-180deg);
    }

    .accordion__body {
      height: auto;
      padding: space(1.25) space(1.25);
      padding-top: 0;
      overflow: visible;

      @include md {
        padding: 0 space(1.5) space(1.75);
      }
    }
  }

  &--qrForm {
    @extend .u-shadow-small;

    .accordion__header {
      color: getvar($colors, "neutral", "900");
    }

    .Form {
      margin: 0;
      width: 100%;
      max-width: 100%;
    }
  }

  &--dark {
    background-color: transparent !important;
    color: getvar($colors, "neutral", "50");
    border-bottom: 1px solid getvar($colors, "primary", "500");
    border-radius: 0 !important;
    box-shadow: none;

    &--first {
      border-top: 1px solid getvar($colors, "primary", "500");
    }

    .accordion__title {
      color: getvar($colors, "neutral", "50");
      font-weight: 400;
    }

    .accordion__header {
      background-color: transparent;
      padding: space(1.5) space();

      svg {
        color: getvar($colors, "neutral", "50");
      }
    }

    .accordion__body {
      color: getvar($colors, "neutral", "50");
      padding: 0 0 0 space(1.5);

      ul {
        list-style-type: none;
      }

      .button {
        color: getvar($colors, "neutral", "50") !important;
        justify-content: flex-start !important;
        text-align: left;
      }
    }
  }

  &--iconTop {
    .accordion__header {
      flex-direction: column;
      padding: 10px !important;
      .accordion__icon {
        padding-left: 0 !important;
        svg {
          margin-right: 0 !important;
        }
      }
      .accordion__title {
        font-size: 10px;
        text-align: center;
      }
    }
  }

  &--iconLeft {
    background-color: getvar($colors, "primary", "50");
    box-shadow: none !important;
    .accordion__header {
      flex-direction: row-reverse;
      justify-content: flex-end !important;
      gap: 10px;

      .accordion__button {
        transform: rotate(-90deg) !important;
        transition: transform 0.2s ease-in-out;
      }
    }

    .accordion__body {
      height: 0;
      overflow: hidden;
      transition: height 0.2s ease-in-out;
      color: getvar($colors, "neutral", "900");

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.accordion--iconLeft {
  .accordion__body {
    height: 0;
    display: none;
    transition: height 0.2s ease-in-out;
    color: getvar($colors, "neutral", "900");

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.accordion--iconLeft.accordion--isOpen {
  .accordion__header {
    .accordion__button {
      transform: rotate(-180deg) !important;
      transition: transform 0.2s ease-in-out;
    }
  }

  .accordion__body {
    height: 100%;
    padding: space(1.25) space(1.25);
    padding-top: 0;
    display: block;

    @include md {
      padding: 0 space(1.5) space(1.75);
    }
  }
}
