@import "src/themes/mojito/styles/index.scss";
.Alert {
  border-radius: space(0.5);
  align-items: flex-start;
  display: flex;
  padding: 8px 24px;
  justify-content: space-between;
  flex-direction: column;
  background-color: getvar($colors, "neutral", "100");

  @include md {
    align-items: center;
    flex-direction: row;
  }

  &--default {
    background-color: transparent;
  }

  &--warning {
    background-color: getvar($colors, "warning", "400");
    color: getvar($colors, "warning", "800");
  }

  &--error {
    background-color: getvar($colors, "error", "400");
    color: getvar($colors, "error", "800");
  }

  &--left {
    display: flex;
    margin-bottom: 12px;
    @include md {
      margin-bottom: 0px;
      align-items: center;
    }

    p {
      margin-bottom: 0;
      font-weight: 600;
    }
  }
  &--right {
    width: 100%;
    @include md {
      width: auto;
    }
  }

  svg {
    height: space(1.5);
    width: space(1.5);
    margin-right: space(0.5);
    flex-shrink: 0;

    @include md {
      height: space(1.5);
      width: space(1.5);
      margin-right: space(0.5);
    }
  }

  &__text {
    width: 100%;
  }

  .alert-button {
    min-width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  &--centered {
    justify-content: center;
  }
}
