@import "src/themes/mojito/styles/index.scss";
.ListPicker {
  &__list {
    display: flex;
    gap: space(0.5);
    padding-left: 0;
    background-color: getvar($colors, "neutral", "100");
    padding: space();
    border-radius: space(0.5);
    flex-wrap: wrap;
    justify-content: center;

    @include md {
      gap: space(0.75);
    }
  }

  &__input-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: space(0.5);
    width: 100%;

    @include sm {
      flex-wrap: nowrap;
      width: auto;
    }

    .Input {
      width: 100%;
      margin-top: space(0);
    }

    .ListPicker__icon {
      margin-right: space(0.75);

      @include md {
        margin-right: space(1.5);
      }
    }

    &--extended {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      width: 100%;

      @include sm {
        width: auto;
      }
    }
  }

  &__social-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 0 1.5rem 0;
    width: 100%;
    position: relative;
    column-gap: 2rem;
    &:not(:last-child) {
      border-bottom: 1px solid getvar($colors, "neutral", "200");
    }
    &:not(:first-child) {
      border-top: 1px solid getvar($colors, "neutral", "200");
    }

    @include sm {
      column-gap: space(1);
    }
    @include lg {
      column-gap: space(2);
    }
  }

  &__social-name {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 1;
    column-gap: 0.75rem;
    justify-content: flex-start;
    align-items: center;
    min-width: space(8.25);
    margin-bottom: space(1);

    @include sm {
      min-width: space(6.5);
      margin-bottom: 0;
      align-items: flex-start;
      flex-direction: column;
    }

    @include lg {
      justify-content: flex-start;
      flex-direction: row;
      min-width: space(8.25);
      align-items: center;
    }
    .ListPicker__label {
      min-width: 0;
    }
  }

  &__label {
    margin-right: space(0.75);
    @include sm {
      margin-top: space(0.5);
    }

    @include lg {
      margin-right: 0;
      min-width: space(9.5);
    }
  }

  &__item {
    background-color: getvar($colors, "neutral", "50");
    border: solid 1px getvar($colors, "neutral", "200");
    border-radius: space(0.75);
    height: space(3);
    list-style: none;
    width: space(3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 0;

    @include md {
      height: space(2.8);
      width: space(2.8);
    }

    &--active {
      @extend .u-active;
    }

    &--mod {
      width: auto;
      height: auto;
      padding: space(0.25);

      .ListPicker__icon {
        svg {
          height: auto;
          width: auto;
        }
      }
    }
  }

  &__icon {
    svg {
      height: space(2);
      width: space(2);
    }

    div {
      display: flex;
    }
  }

  &__remove {
    margin-left: space(0.75);
    margin-right: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: space(0.625);

    @include sm {
      position: relative;
      margin-top: 0px;
    }

    @include md {
      cursor: pointer;
      margin-left: space(1.5);
      position: relative;
      top: auto;
      right: auto;
    }

    div {
      display: flex;
      width: 30px;
      height: 30px;

      span {
        width: 25px;
        height: 25px;
        display: inline-block;
        border: 3px solid getvar($colors, "neutral", "300");
        border-radius: 50%;
        position: relative;

        &::after {
          content: "-";
          font-size: 3rem;
          position: absolute;
          color: getvar($colors, "neutral", "300");
          transform: translate(2px, -2px);
          top: 1px;
          right: 3px;
        }
      }
    }
  }
}
